<template>
  <div class="col-lg-7 mb-5">
    <Agile :options="myOptions" class="owl-carousel owl-theme landing-page-slider theme-slider ltr" v-if="sortDealData.length>0">
      <div class="slide" v-for="(item, index) in sortDealData" :key="index">

        <b-img-lazy
          v-bind="mainProps"
          class="background-image"
          :src="imageUrl(item)"
          :alt="item.hotelName"
          onerror="this.src='assets/img/lesiurpackages_hotelsimg4.jpg'"
        ></b-img-lazy>

          <div class="caption" >
            <div class="con" dir="rtl">
              <h4>
                {{ item.hotelName }}, {{ item.extendedProperties.destination.name}}
              </h4>
                <ul >
                  <li><strong >יציאה:</strong> <i class="far fa-calendar-alt"></i>
                    {{ changeDateFormat(item.checkinDate) }}
                  </li>
                  <li><strong >חזרה:</strong> <i class="far fa-calendar-alt"></i>
                    {{ changeDateFormat(item.checkoutDate) }}
                  </li>
                </ul>
              <h3>₪<strong>{{Math.round(item.totalAfterDiscount)}}</strong> <small>{{ item.priceRemark }}</small></h3>
              <button class="btn btn-primary" :class="`${landingInfo.utm_source} - ${destinationName}`">
                <a style="color:white;" :href="`${item.productPageUrl}&roomClass=${item.roomClass}`">
                  להזמנה לחץ כאן <i class="fas fa-caret-left"></i></a>
              </button>
            </div>
          </div>
      </div>

      <template slot="prevButton"><i class="fas fa-chevron-left"></i></template>
      <template slot="nextButton"><i class="fas fa-chevron-right"></i></template>

    </Agile>
  </div>
</template>

<script>
import { BImgLazy } from 'bootstrap-vue';
import { mapGetters } from 'vuex';

export default {
  name: 'LandingBannerSlide',
  components: {
    Agile: () => import('@/components/atoms/agile/Agile'),
    BImgLazy,
  },
  props: {
    dealTypeCode: String,
  },
  computed: {
    ...mapGetters({
      landingDealData: 'GET_LANDING_DEAL_DATA',
      landingInfo: 'GET_LANDING_INFO',
      destinationImages: 'GET_DESTINATION_IMAGES',
      destinationList: 'GET_DOMESTIC_DESTINATION',
    }),
    destinationName() {
      const { destinationList } = this;
      if (!destinationList) return '';
      return destinationList.find((item) => item.code === this.dealTypeCode).name;
    },
  },
  data() {
    return {
      slide: 0,
      sortDealData: [],
      myOptions: {
        autoplay: true,
        infinite: true,
        navButtons: true,
        autoplaySpeed: 5000,
        dots: true,
        speed: 2000,
        fade: true,
      },
      mainProps: {
        fluidGrow: true,
        blank: true,
        blankColor: '#bbb',
        class: 'card-img-top img-fluid',
      },
    };
  },
  mounted() {
    if (this.landingDealData && this.landingDealData.length > 0) {
      this.generateCheapestDeals(this.landingDealData);
    }
  },
  watch: {
    landingDealData(newDeals) {
      this.generateCheapestDeals(newDeals);
    },
    dealTypeCode() {
      this.generateCheapestDeals(this.landingDealData);
    },
  },
  methods: {
    imageUrl(item) {
      let url = '';

      if (!item) return '';
      url = (item.imageUrl !== 'null') ? url = item.imageUrl : null;
      url = url || item.extendedProperties.hotelInfo.mainImageUrls[0] || item.extendedProperties.hotelConversion.imageUrl || 'assets/img/lesiurpackages_hotelsimg5.jpg';
      return url;
    },
    generateCheapestDeals(pDeals) {
      if (pDeals.length > 0) {
        const filteredDeals = pDeals.filter((item) => item.dealTypeCode === this.dealTypeCode);
        const tmpDeals = (this.dealTypeCode === '' || filteredDeals.length < 4) ? [...pDeals] : filteredDeals;
        tmpDeals.sort((a, b) => {
          const priceA = a.totalAfterDiscount, priceB = b.totalAfterDiscount;
          return priceA > priceB;
        });
        this.sortDealData = tmpDeals.slice(0, 4);
      }
    },
    changeDateFormat(str) {
      return str.split('T')[0].split('-').reverse().join('/');
    },
    getDestinationImage(dest) {
      const matchedItem = this.destinationImages.find((d) => d.code === dest);
      let url = (matchedItem) ? this.destinationImages.find((d) => d.code === dest).imageUrls[0] : 'assets/img/lesiurpackages_hotelsimg3.jpg';
      if (!url) url = 'assets/img/lesiurpackages_hotelsimg3.jpg';
      return url;
    },
  },
};
</script>

<style scoped>
.landing-page-slider .slide {
  width: 100%;
  position: relative;
  box-sizing: border-box;
}
.landing-page-slider .slide img.background-image {
    display: flex;
    min-height: 500px;
    max-height: 500px;
    object-fit: cover;
}
.landing-page-slider .slide .caption {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    width: 100%;
    text-align: left;
    z-index: 99;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
}
.landing-page-slider .slide .con {
    max-width: 100%;
    margin: auto;
    max-width: 460px;
    width: 100%;
    margin: auto;
    text-align: center;
    background-color: rgb(0 0 0 / 37%);
    border: 5px solid rgb(255 255 255 / 33%);
    color: #fff;
    box-sizing: border-box;
    padding: 30px;
}
.landing-page-slider .slide .con {
    z-index: 5;
    position: relative;
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
    opacity: 0;
    transition: opacity 0.4s ease, transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
}
.landing-page-slider .slide.agile__slide--active .con {
  transform: translate3d(0,0,0);
  opacity: 1;
  transition: opacity 0.4s ease, transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
}
.landing-page-slider .slide.agile__slide--active .con>* {
  -webkit-animation: fade-down 1.5s ease both;
  animation: fade-down 1.5s ease both;
}

.landing-page-slider .slide .con h4 {
    font-size: 1.5em;
    margin-bottom: 6px;
    text-transform: capitalize;
    font-weight: bold;
}
.landing-page-slider .slide .con ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.landing-page-slider .slide .con ul li {
    display: block;
    border: 1px solid rgb(255 255 255 / 36%);
    margin-top: 2px;
    box-sizing: border-box;
    padding: 5px 5px;
    font-size: 1.2rem;
    background-color: rgb(0 0 0 / 48%);
    max-width: 390px;
    margin: auto;
    margin-top: -1px;
}
.landing-page-slider .slide .con ul li strong {
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 1em;
    margin-right: 5px;
}
.landing-page-slider .slide .con h3 {
    font-size: 1.5rem;
    text-transform: capitalize;
    font-weight: 700;
    margin-top: 5px;
    letter-spacing: 0.5px;
}
.landing-page-slider .slide .con .btn-primary {
    font-size: 1em;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
}
.landing-page-slider .slide .con ul li img {
    max-width: 20px;
    width: 100%;
    height: auto;
    display: inline-block;
    object-fit: contain;
    vertical-align: middle;
    margin-right: 7px;
    margin-left: 5px;
}
</style>
<style>
.landing-page-slider .agile__list{
  box-shadow: 0px 0px 2px 9px rgb(0 97 171 / 15%);
}
.landing-page-slider .agile__actions .agile__dots {
  position: absolute;
  bottom: 20px;
}
.landing-page-slider .agile__nav-button{
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 40px;
  opacity: 0.5;
  transform: scaleY(1.5);
}
.landing-page-slider .agile__nav-button--prev {
  left: 35px;
  right: auto;
}
.landing-page-slider .agile__nav-button--next {
  right: 35px;
  left: auto;
}
.landing-page-slider .agile__nav-button:hover {
  box-shadow: none;
    opacity: 0.7;
}
@media (max-width: 768px){
  .landing-page-slider.owl-carousel.theme-slider {
    padding: 0;
    width: calc(100% - 15px);
    margin: auto;
  }
  .landing-page-slider .agile__nav-button--prev {
    left: -5px;
    right: auto;
  }
  .landing-page-slider .agile__nav-button--next {
    right: -5px;
    left: auto;
  }
}
</style>
